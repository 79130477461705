import React, { Fragment, useEffect, useState } from 'react';
import {
    Button,
    HStack,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { ModalProps } from '../../../modals/types';
import { ModalWithNoInitialFocus } from '@recourseai/components/src/atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';

export type AvatarModalName =
    | 'AvatarSessionCouldNotInitialise'
    | 'AvatarSessionDisconnectedTtsFailed'
    | 'AvatarSessionDisconnectedAsrFailed'
    | 'AvatarSessionDisconnectedTimeout';

type ModalTemplate<T> = {
    code: AvatarModalName;
    title: string;
    content: JSX.Element;
    actions?: (
        props: T,
    ) => {
        label: string;
        loadingText?: string;
        onClick: (onActionComplete?: () => void) => Promise<void> | void;
    }[];
    modalProps?: any; // ThemingProps<'Modal'>;
};

const ModalFactory = <T,>(template: ModalTemplate<T>) => {
    const _Modal: React.FC<T & ModalProps> = props => {
        const [runningAction, setRunningAction] = useState<null | string>(null);
        const actions = template.actions?.(props);

        useEffect(() => {
            if (!props.show) {
                setRunningAction(null);
            }
        }, [props.show]);

        return (
            <ModalWithNoInitialFocus
                isOpen={props.show}
                closeOnOverlayClick={false}
                onClose={props.onClose}
                {...template.modalProps}
                size='mobile-sm'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{template.title}</ModalHeader>
                    <ModalBody>{template.content}</ModalBody>
                    <ModalFooter>
                        <HStack>
                            {actions?.map(({ label, ...props }) => (
                                <Button
                                    key={label}
                                    colorScheme='brand.black'
                                    isLoading={runningAction === label}
                                    isDisabled={
                                        !!runningAction &&
                                        runningAction !== label
                                    }
                                    {...props}
                                    onClick={async () => {
                                        setRunningAction(label);
                                        await props?.onClick(() =>
                                            setRunningAction(null),
                                        );
                                    }}
                                >
                                    {label}
                                </Button>
                            ))}
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </ModalWithNoInitialFocus>
        );
    };
    _Modal.displayName = template.code + 'Modal';
    return _Modal;
};

export const AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE: ModalTemplate<{
    finish: () => void;
    tryAgainAvatar: () => void;
}> = {
    code: 'AvatarSessionDisconnectedTimeout',
    title: 'The session has disconnected',
    content: (
        <Fragment>
            <p>
                You have taken longer than the recommended time to complete this
                activity.
            </p>
            <p>
                Don’t worry, all your progress is saved. Simply rejoin and
                continue with your conversation, or you may finish and see your
                feedback.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Finish',
            loadingText: 'Finishing',
            onClick: props.finish,
        },
        {
            label: 'Rejoin',
            loadingText: 'Rejoining',
            onClick: props.tryAgainAvatar,
        },
    ],
};

export const AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE: ModalTemplate<{
    useTextChat: () => void;
    tryAgainAvatar: () => void;
}> = {
    code: 'AvatarSessionDisconnectedTtsFailed',
    title: 'Try optimizing your setup',
    content: (
        <Fragment>
            <p className='font-weight-bold'>
                Please ensure you close other applications and tabs/windows and
                plug in charger.
            </p>
            <p>
                You can continue in text-mode and always enable the avatar again
                later by clicking the avatar icon.
            </p>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Continue with text',
            onClick: props.useTextChat,
        },
        {
            label: 'Resume with avatar',
            loadingText: 'Resuming',
            onClick: props.tryAgainAvatar,
        },
    ],
    modalProps: {
        size: 'lg',
    },
};

export const AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE: ModalTemplate<{
    useTextChat: () => void;
    tryAgainAvatar: () => void;
}> = {
    code: 'AvatarSessionCouldNotInitialise',
    title: "It seems we're having trouble loading your avatar at the moment.",
    content: (
        <Fragment>
            <dl>
                <dt>
                    <strong>Try Again Later</strong>
                </dt>
                <dd>
                    <Text paddingLeft='3'>
                        You can wait for a while and try loading your avatar
                        again later or continue the interaction using text chat
                        (without the avatar). The loading process may succeed on
                        a subsequent attempt.
                    </Text>
                </dd>
            </dl>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Continue with text',
            onClick: props.useTextChat,
        },
        {
            label: 'Try again',
            loadingText: 'Loading',
            onClick: props.tryAgainAvatar,
        },
    ],
    modalProps: {
        size: '2xl',
    },
};

export const AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE: ModalTemplate<{
    useTextChat: () => void;
    retryAsr: () => void;
}> = {
    code: 'AvatarSessionDisconnectedAsrFailed',
    title:
        'It seems we are having trouble with speech recognition at the moment.',
    content: (
        <Fragment>
            <p className='font-weight-bold'>
                Please ensure you have allowed the browser to use your
                microphone and that no other applications are using it.
            </p>
            <p>
                You can continue in text-mode and always enable the avatar again
                later by clicking the avatar icon.
            </p>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    actions: props => [
        {
            label: 'Continue with text',
            onClick: props.useTextChat,
        },
        {
            label: 'Try again',
            loadingText: 'Trying',
            onClick: props.retryAsr,
        },
    ],
    modalProps: {
        size: 'lg',
    },
};

export const AvatarSessionCouldNotInitialiseModal = ModalFactory(
    AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE,
);

export const AvatarSessionDisconnectedTtsFailedModal = ModalFactory(
    AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
);

export const AvatarSessionDisconnectedAsrFailedModal = ModalFactory(
    AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE,
);

export const AvatarSessionDisconnectedTimeoutModal = ModalFactory(
    AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
);

export const getModalProps = (name: AvatarModalName) =>
    [
        AVATAR_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
        AVATAR_SESSION_DISCONNECTED_ASR_FAILED_MODAL_TEMPLATE,
        AVATAR_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
        AVATAR_SESSION_COULD_NOT_INITIALISE_MODAL_TEMPLATE,
    ].find(t => t.code === name)?.modalProps;
