import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import {
    AttachmentsModal,
    AvatarTemplate,
    ConfirmFinishModal,
    ErrorModal,
    Navbar,
    SourcesSidePanel,
    TextTemplate,
} from '@recourseai/components';
import LoadingSpinner from '../components/generic/LoadingSpinner';
import { InteractionsContextProvider } from '@recourseai/components/src/utils/interaction/InteractionContext';
import { Center, Heading, Text } from '@chakra-ui/react';
import { useStoreWithArray } from '@recourseai/components/src/stores';
import config from '../consts/config';
import InteractionFeedbackModal from '../components/modals/InteractionFeedbackModal';
import StudentActivityFeedbackModal from '../components/modals/StudentActivityFeedbackModal';
import useSyncQueue from '../hooks/useSyncQueue';
import FPSDisplay from '@recourseai/components/src/atoms/FPSDisplay';
import { useActivityInitialConfig, useAttempt } from './useExerciseActivity';
import ResumeCompletedAttemptModal from '../components/modals/ResumeCompletedAttemptModal';
import ResumeCancelledAttemptModal from '../components/modals/ResumeCancelledAttemptModal';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

interface Props {
    activityId: string;
    courseId: string | undefined;
    onClose?: () => void;
}

const Interaction: React.FC<Props> = ({ activityId, courseId, onClose }) => {
    const {
        isTextMode,
        currentModal,
        setCurrentModal,
        errorModalMessage,
        closeModal,
        isSourcesDrawerOpen,
        openDrawer,
        closeDrawer,
        attachmentToDisplay,
    } = useStoreWithArray([
        'isTextMode',
        'currentModal',
        'setCurrentModal',
        'errorModalMessage',
        'closeModal',
        'isSourcesDrawerOpen',
        'openDrawer',
        'closeDrawer',
        'attachmentToDisplay',
    ]);

    const { isLandscape } = useMobileOrientation();

    const {
        attempt,
        reset,
        feedback,
        activity,
        exerciseAPI,
        completeAttempt,
        session,
        isComplete,
        isCompleting,
        hasFailedToComplete,
    } = useAttempt(activityId, courseId);

    useActivityInitialConfig(activity?.config.initial_config);

    const [
        feedbackModal,
        enqueueFeedbackModal,
        dequeueFeedbackModal,
        feedbackModalQueue,
    ] = useSyncQueue<
        | 'FEEDBACK'
        | 'ACTIVITY_FEEDBACK'
        | 'RESUME_COMPLETED_ATTEMPT'
        | 'RESUME_CANCELLED_ATTEMPT'
    >();

    const isSessionCompleted = session?.status === 'completed' && !!attempt;
    const isSessionCancelled = session?.status === 'cancelled' && !!attempt;
    useEffect(() => {
        if (isSessionCompleted) {
            // setTimeout used to deal with
            // Warning: flushSync was called from inside a lifecycle method
            setTimeout(
                () => enqueueFeedbackModal('RESUME_COMPLETED_ATTEMPT'),
                0,
            );
            completeAttempt(attempt?.id);
        }
        if (isSessionCancelled) {
            setTimeout(
                () => enqueueFeedbackModal('RESUME_CANCELLED_ATTEMPT'),
                0,
            );
        }
    }, [
        isSessionCompleted,
        enqueueFeedbackModal,
        completeAttempt,
        attempt?.id,
        isSessionCancelled,
    ]);

    useEffect(() => {
        if (
            (isComplete || hasFailedToComplete) &&
            feedbackModalQueue.length === 0 &&
            !!activity
        ) {
            onClose && onClose();
        }
    }, [
        feedbackModalQueue,
        activity,
        onClose,
        isComplete,
        hasFailedToComplete,
    ]);

    const [currentSource, setCurrentSource] = useState<Citation>();

    const onCitationClick = (citation: Citation) => {
        openDrawer();
        setCurrentSource(citation);
    };

    if (!activity || !session || !attempt) {
        return (
            <Center h='100%'>
                <LoadingSpinner />
            </Center>
        );
    }

    const isSessionsInactive = isSessionCompleted || isSessionCancelled;
    const showFeedback =
        isCompleting || isComplete || hasFailedToComplete || isSessionsInactive;

    if (showFeedback) {
        return (
            <Fragment>
                <ResumeCancelledAttemptModal
                    show={feedbackModal === 'RESUME_CANCELLED_ATTEMPT'}
                    onClose={() => {
                        dequeueFeedbackModal();
                        return Promise.resolve();
                    }}
                    onStartNew={() => {
                        return reset().finally(dequeueFeedbackModal);
                    }}
                />
                <ResumeCompletedAttemptModal
                    show={feedbackModal === 'RESUME_COMPLETED_ATTEMPT'}
                    onClose={() => {
                        dequeueFeedbackModal();
                        return Promise.resolve();
                    }}
                    onViewFeedback={() => {
                        enqueueFeedbackModal('FEEDBACK');
                        dequeueFeedbackModal();
                        return Promise.resolve();
                    }}
                />
                <InteractionFeedbackModal
                    show={feedbackModal === 'FEEDBACK'}
                    onClose={dequeueFeedbackModal}
                    feedback={feedback}
                    isError={hasFailedToComplete}
                    tryAgain={() => completeAttempt(attempt?.id)}
                />
                <StudentActivityFeedbackModal
                    show={feedbackModal === 'ACTIVITY_FEEDBACK'}
                    onClose={dequeueFeedbackModal}
                    activity={activity}
                    context={{ attemptId: attempt.id }}
                />
            </Fragment>
        );
    }

    return (
        <InteractionsContextProvider
            session={session}
            useStreaming={activity!.config.use_streaming}
            exerciseAPI={exerciseAPI!}
            requiredProcessors={isTextMode ? ['text'] : ['audio', 'text']}
            // Can be null
            messageDisplayLimit={
                activity!.config.max_history_messages || undefined
            }
        >
            {isMobile && !isTablet && isLandscape && (
                <Center
                    bg='white'
                    w='100%'
                    h='100%'
                    position='fixed'
                    zIndex={100}
                    flexDirection='column'
                >
                    <Heading>Please rotate your device</Heading>
                    <Text>
                        We do not support landscape mode on mobiles. Please go
                        back to portrait mode
                    </Text>
                </Center>
            )}
            <AttachmentsModal
                show={currentModal === 'attachment'}
                onClose={closeModal}
                attachment={attachmentToDisplay!}
            />
            <ConfirmFinishModal
                courseId={courseId}
                show={currentModal === 'finish'}
                onClose={closeModal}
                onResumeLater={() => {
                    closeModal();
                    onClose && onClose();
                }}
                onComplete={() => {
                    enqueueFeedbackModal('FEEDBACK');
                    completeAttempt(attempt?.id, {
                        onSuccess: () => {
                            if (activity?.student_feedback_allowed) {
                                enqueueFeedbackModal('ACTIVITY_FEEDBACK');
                            }
                        },
                    });
                }}
            />
            <ErrorModal
                show={currentModal === 'error'}
                onClose={closeModal}
                error={errorModalMessage}
            />
            <Navbar
                title={activity.title}
                courseId={courseId}
                onClickEnd={() => setCurrentModal('finish')}
                onClickClose={() => onClose && onClose()}
            />
            <SourcesSidePanel
                isSourcesDrawerOpen={isSourcesDrawerOpen}
                onClose={() => {
                    closeDrawer();
                }}
                currentSource={currentSource}
            />
            {!isTextMode && (
                <AvatarTemplate
                    sessionId={session.id}
                    transcriber_config={
                        session.active_stage.interaction_stage
                            .transcriber_config
                    }
                    processor_configs={
                        session.active_stage.interaction_stage.processor_configs
                    }
                    avatar_config={
                        session.active_stage.interaction_stage
                            .avatar_config as any
                    }
                    backgroundSrc={
                        session.active_stage.interaction_stage.background_id
                    }
                    title={activity.title}
                    onCitationClick={onCitationClick}
                />
            )}
            {isTextMode && (
                <TextTemplate
                    title={activity.title}
                    onCitationClick={onCitationClick}
                />
            )}
            {config.REACT_APP_ENV !== 'production' && (
                <FPSDisplay position='fixed' bottom={0} right={0} />
            )}
        </InteractionsContextProvider>
    );
};

export default Interaction;
