import * as React from 'react';
import { Fragment } from 'react';
import {
    Center,
    Flex,
    Tooltip,
    Text,
    VStack,
    Heading,
    HStack,
    ChakraProvider,
    Button,
    IconButton,
} from '@chakra-ui/react';
import InteractionModeSwitch from '../InteractionModeSwitch/InteractionModeSwitch';
import NavbarToggle from '../../molecules/NavbarToggle/NavbarToggle';
import { themeWhiteShadows } from '../../theme';
import { isMobile, isTablet } from 'react-device-detect';
import { useStoreWithArray } from '../../stores';
import { Icon } from '../../atoms';

interface Props {
    title: string;
    courseId: string | undefined;
    onClickEnd: () => void;
    onClickClose: () => void;
}

const Navbar: React.FC<Props> = ({
    title,
    courseId,
    onClickEnd,
    onClickClose,
}) => {
    const {
        isCaptionsOn,
        toggleCaptions,
        isTextMode,
        setIsTextMode,
    } = useStoreWithArray([
        'isCaptionsOn',
        'toggleCaptions',
        'isTextMode',
        'setIsTextMode',
    ]);

    return (
        <ChakraProvider theme={themeWhiteShadows} cssVarsRoot='#navbar'>
            <Flex
                id='navbar'
                p='10px'
                position='fixed'
                w='100%'
                justifyContent='space-between'
                zIndex={20}
            >
                <Center>
                    <HStack>
                        <InteractionModeSwitch
                            isTextMode={isTextMode}
                            setIsTextMode={setIsTextMode}
                        />
                        {!isTextMode && isMobile && (
                            <NavbarToggle
                                name='Captions'
                                icon='captions'
                                isToggle={isCaptionsOn}
                                onClick={toggleCaptions}
                            />
                        )}
                    </HStack>
                </Center>
                <VStack maxW='40%'>
                    {isTextMode && (
                        <Fragment>
                            <Heading
                                w='100%'
                                color='white'
                                fontSize='lg'
                                mt='5px'
                                isTruncated
                            >
                                {title}
                            </Heading>
                            <Text
                                color='dark.400'
                                fontSize='md'
                                mt='-5px!important'
                            >
                                By Recourse AI
                            </Text>
                        </Fragment>
                    )}
                </VStack>
                <Center>
                    <Tooltip
                        label={
                            courseId
                                ? 'Complete activity to see your feedback'
                                : 'You will be able to resume later'
                        }
                        placement='bottom'
                    >
                        {isMobile && !isTablet ? (
                            <IconButton
                                aria-label='End button'
                                borderRadius='10px'
                                colorScheme='green'
                                size='lg'
                                onClick={courseId ? onClickEnd : onClickClose}
                            >
                                <Icon
                                    iconChoice='x'
                                    fontAwesomeProps={{ size: 'lg' }}
                                />
                            </IconButton>
                        ) : (
                            <Button
                                borderRadius='10px'
                                colorScheme='green'
                                lineHeight='normal'
                                onClick={courseId ? onClickEnd : onClickClose}
                            >
                                {courseId ? 'End' : 'Close'}
                            </Button>
                        )}
                        {/*    <IconButton*/}
                        {/*        aria-label='End conversation button'*/}
                        {/*        rounded='full'*/}
                        {/*        backgroundColor='black'*/}
                        {/*        border='1px solid'*/}
                        {/*        borderColor='dark.500'*/}
                        {/*        _hover={{*/}
                        {/*            backgroundColor: 'dark.480',*/}
                        {/*            borderColor: 'dark.430',*/}
                        {/*        }}*/}
                        {/*        onClick={onClickClose}*/}
                        {/*    >*/}
                        {/*        <Icon*/}
                        {/*            iconChoice='x'*/}
                        {/*            fontAwesomeProps={{ color: 'white' }}*/}
                        {/*        />*/}
                        {/*    </IconButton>*/}
                    </Tooltip>
                </Center>
            </Flex>
        </ChakraProvider>
    );
};

export default Navbar;
