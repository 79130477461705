import {
    Box,
    HStack,
    IconButton,
    Slide,
    Image,
    VStack,
    Flex,
    Button,
    Avatar,
} from '@chakra-ui/react';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@recourseai/components';
import { MOBILE_MENU_HEIGHT } from '@recourseai/components/src/theme/consts';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../../consts/config';
import useAsyncError from '../../../hooks/useAsyncError';
import Logo from '../../../images/logo_ai_white_full.svg';
import { authService, keycloak, moocAPI } from '../../../services';
import { MoocFeature } from '../../contexts/StudentFeaturesContext';
import ModalOpener from '../../generic/ModalOpener';
import UserSettingsModal from '../../modals/UserSettingsModal';
import NavItem from './NavItem';
import { NavItemsMobile } from './SidebarItemsConfig';

interface Props {
    routePrefix: string;
    features: MoocFeature[];
}

const MenuButton = ({ onClick }: { onClick: () => void }) => (
    <IconButton
        aria-label='menu button'
        h='100%'
        background='transparent'
        _hover={{ backgroundColor: 'transparent' }}
        _active={{ backgroundColor: 'transparent' }}
        onClick={onClick}
        zIndex={30}
    >
        <Icon
            iconChoice='hamburger'
            fontAwesomeProps={{
                color: 'white',
                size: 'lg',
            }}
        />
    </IconButton>
);

export default function MobileMenu({ routePrefix, features }: Props) {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const location = useLocation();

    const throwAsyncError = useAsyncError();
    const { data: userData, isSuccess: userDataLoaded } = useQuery(
        ['user-data'],
        () => moocAPI.get('student/user'),
        {
            onError: throwAsyncError,
        },
    );

    const isNavItemActive = (route: string) =>
        (route === '/courses' &&
            location.pathname.startsWith(`${routePrefix}${'/activity'}`)) ||
        location.pathname.startsWith(`${routePrefix}${route}`);

    return (
        <Fragment>
            <Box
                backgroundColor='dark.600'
                position='sticky'
                top={0}
                w='100%'
                h={MOBILE_MENU_HEIGHT}
                zIndex={30}
            >
                <MenuButton
                    onClick={() => setIsMobileMenuOpen(current => !current)}
                />
            </Box>
            <Slide
                direction='left'
                in={isMobileMenuOpen}
                style={{
                    zIndex: 30,
                    width: '210px',
                }}
            >
                <Flex
                    h='100%'
                    backgroundColor='dark.600'
                    color='white'
                    direction='column'
                >
                    <HStack h={MOBILE_MENU_HEIGHT} mb='10px'>
                        <MenuButton
                            onClick={() =>
                                setIsMobileMenuOpen(current => !current)
                            }
                        />
                        <Image
                            src={Logo}
                            h='18px'
                            alt={config.REACT_APP_BRANDING_TITLE}
                        />
                    </HStack>
                    <VStack spacing={5} alignItems='stretch' px='10px'>
                        {NavItemsMobile.filter(item =>
                            features.includes(item.feature),
                        ).map(item => (
                            <NavItem
                                key={item.route}
                                route={item.route}
                                isActive={isNavItemActive(item.route)}
                                icon={item.icon}
                                fontSize='large'
                            >
                                {item.text}
                            </NavItem>
                        ))}
                    </VStack>
                    <Flex
                        direction='column'
                        m='auto 10px 10px 10px'
                        border='1px solid'
                        borderColor='dark.500'
                    >
                        {!(keycloak?.tokenParsed as any)?.external_idp && (
                            <ModalOpener Modal={UserSettingsModal}>
                                <Button
                                    variant='ghost'
                                    justifyContent='start'
                                    borderRadius={0}
                                    fontWeight='normal'
                                    leftIcon={<FontAwesomeIcon icon={faCog} />}
                                >
                                    Settings
                                </Button>
                            </ModalOpener>
                        )}
                        <Button
                            variant='ghost'
                            justifyContent='start'
                            borderRadius={0}
                            fontWeight='normal'
                            leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
                            onClick={() => authService.logout()}
                        >
                            Log out
                        </Button>
                    </Flex>
                    <HStack m='0px 10px 10px 10px' justifyContent='center'>
                        <Avatar
                            size='md'
                            name={
                                userDataLoaded
                                    ? `${userData.first_name} ${userData.last_name}`
                                    : undefined
                            }
                            className='fs-mask'
                            color='white'
                            bg='brand.gradient.full'
                            cursor='pointer'
                        />
                        {userDataLoaded && (
                            <Flex
                                direction='column'
                                wordBreak='break-all'
                                className='fs-exclude'
                                fontSize='xs'
                            >
                                <HStack spacing={1}>
                                    <Box>{userData.first_name}</Box>
                                    <Box>{userData.last_name}</Box>
                                </HStack>
                                {userData.email}
                            </Flex>
                        )}
                    </HStack>
                </Flex>
            </Slide>
        </Fragment>
    );
}
